import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import omit from 'lodash.omit';
import {ResponsiveContainer, useContainerSize} from '@components/layout';
import GuidingPrinciple, {GuidingPrincipleProps} from './GuidingPrinciple';
import StairGrid from '@components/layout/StairGrid';
import {styled, ThemeProvider, Colors} from '@styles';
import RichTextCallout, {
  RichTextProps,
} from '@components/SectionCallout/RichTextCallout';
import MobileDrawer from './MobileDrawer';
import {Section} from '@components/sectioning';

export interface GuidingPrinciplesProps {
  headerbodyBlock: RichTextProps;
  iconcopyBlock: GuidingPrincipleProps[];
  expandLabel?: string;
}

const Container = styled(props => (
  <ResponsiveContainer as={Section} {...omit(props, 'bgImage')} />
))`
  background-color: ${Colors.FogLight};
  padding: 2em 2em 0;
  &.wide {
    background-color: unset;
    background-image: url(${({bgImage}) => bgImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: -5em;
    padding: 12.5em 0 6.5em;
  }
`;

const InnerContainer = styled(ResponsiveContainer).withConfig({
  componentId: 'guidingPrincipleIndexInnerContainer'
})`
  max-width: 80%;
  margin: 0 auto;
`;

const BG_IMAGE_QUERY = graphql`
  query {
    bgImage: file(relativePath: {eq: "2U-Background-Light-Gray.svg"}) {
      publicURL
    }
  }
`;

const GuidingPrinciples = ({
  headerbodyBlock,
  iconcopyBlock,
}: GuidingPrinciplesProps): JSX.Element => {
  const {bgImage} = useStaticQuery(BG_IMAGE_QUERY);
  const {wide} = useContainerSize();
  return (
    <ThemeProvider theme={{fg: Colors.Charcoal, bg: Colors.FogLight}}>
      <Container bgImage={bgImage.publicURL}>
        {wide ? (
          <InnerContainer>
            <ThemeProvider theme={{minPadding: '2em 0', maxWidth: '718px'}}>
              <RichTextCallout {...headerbodyBlock} />
            </ThemeProvider>
            <StairGrid
              numberOfRows={3}
              gridItems={iconcopyBlock}
              GridItemComponent={GuidingPrinciple}
            />
          </InnerContainer>
        ) : (
          <ThemeProvider theme={{ctaIconBg: Colors.Blue}}>
            <MobileDrawer
              headerbodyBlock={headerbodyBlock}
              iconcopyBlock={iconcopyBlock}
            />
          </ThemeProvider>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default GuidingPrinciples;
